<template>
  <div class="h-full" ref="innercontent" v-if="loaded">
    <div ref="header" name="header" class="px-4 shadow relative">
      <div class="flex content-end justify-between">
        <div>
          <h3 class="text-lg font-semibold pt-2 pb-1">{{ formStructure.label }}</h3>
        </div>
      </div>
      <!-- <div class="absolute top-0 right-0">
        <div
          class="mt-12 mr-8 py-2 text-sm font-thin bg-yellow-200 border-r-4 rounded-sm border-yellow-400 p-2"
        >
          <slot name="info"></slot>
        </div>
      </div>-->
    </div>

    <form @submit.prevent class="max-h-screen pt-2 -mt-10 pt-10">
      <div class="overflow-y-auto shadow">
        <div class="flex-col pt-2 px-4">
          <div class="inline-flex sm:flex sm:mr-2">
            <div v-if="!selected" class="flex-1 mt-1">
              <label class="block text-sm font-normal text-gray-900">
                <span class="pr-1 font-semibold text-gray-900" v-if="variables.drg_name.mandatory">*</span>
                <span>{{ variables.drg_name.label }}</span>
              </label>
              <div class="relative mb-1">
                <cm-select
                  v-model="postData.drug_id"
                  :value="selected"
                  name="drug_id"
                  valueProp="id"
                  nameProp="name"
                  :options="drugs"
                  @change="setDrug"
                />
                <cm-button
                  class="my-2"
                  size="medium"
                  @click.prevent="select"
                  :disabled="postData.drug_id ? false : true"
                  >{{ $t("button.choose") }}</cm-button
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="selected" class="flex-col pt-2 pb-8 px-4">
          <!-- <template> -->
          <div class="flex max-w-lg">
            <div class="mt-1 mr-2">
              <label class="block text-sm font-normal text-gray-900">
                <span class="pr-1 font-semibold text-gray-900" v-if="variables.ordination_date.mandatory">*</span>
                <span>{{ variables.ordination_date.label }}</span>
              </label>
              <cm-datepicker-2 v-model="postData.ordination_date" name="ordination_date" />
            </div>
          </div>
          <div class="flex max-w-lg">
            <div class="mt-1 mr-2">
              <label class="block text-sm font-normal text-gray-900">
                <span class="pr-1 font-semibold text-gray-900" v-if="variables.start_date.mandatory">*</span>
                <span>{{ variables.start_date.label }}</span>
              </label>
              <cm-datepicker-2 v-model="postData.start_date" name="start_date" />
            </div>
          </div>
          <div class="flex max-w-lg">
            <div class="mt-1 mr-2">
              <label class="block text-sm font-normal text-gray-900">
                <span class="pr-1 font-semibold text-gray-900" v-if="variables.end_date.mandatory">*</span>
                <span>{{ variables.end_date.label }}</span>
              </label>
              <cm-datepicker-2 v-model="postData.end_date" name="end_date" />
            </div>
            <div class="mt-1 flex-1">
              <label class="block text-sm font-normal text-gray-900">
                <span class="pr-1 font-semibold text-gray-900" v-if="variables.end_cause">*</span>
                <span>{{ variables.end_cause.label }}</span>
              </label>
              <cm-select
                v-model="postData.end_cause"
                name="end_cause"
                :disabled="postData.end_date ? false : true"
                :options="variables.end_cause.optionslist"
              />
            </div>
          </div>
          <div class="inline-flex sm:flex sm:mr-2">
            <div class="flex-1 mt-1">
              <label class="block text-sm font-normal text-gray-900">
                <span class="pr-1 font-semibold text-gray-900" v-if="variables.therapy_comment.mandatory">*</span>
                <span>{{ variables.therapy_comment.label }}</span>
              </label>
              <div class="relative mb-1">
                <cm-textarea v-model="postData.therapy_comment" name="therapy_comment" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="selected" class="flex pt-2 pb-2 px-4">
          <h3 class="text-base leading-6 font-medium text-gray-900 mr-8">{{ $t("therapy.doses") }}</h3>
          <cm-button size="small" @click="openDoseForm()" v-if="!doseForm">{{ $t("therapy.new_dose") }}</cm-button>
        </div>
        <div class="flex-col pt-2 pb-2 px-4" v-if="selected && doseForm">
          <div class="flex-col max-w-lg">
            <div class="flex">
              <div class="mt-1 mr-2">
                <validation-provider v-slot="v" rules="required">
                  <label
                    class="block text-sm font-normal text-gray-900"
                    :class="{ 'text-red-500': v.validated && v.invalid }"
                  >
                    <span>Datum *</span>
                  </label>
                  <cm-datepicker-2
                    v-model="dose.dose_date"
                    :classes="{
                      'w-24': true,
                      'border-l-4 border-red-300 text-red-900 placeholder-red-300 focus:ring-red focus:border-red-300':
                        v.validated && v.invalid,
                    }"
                  />
                </validation-provider>
              </div>
              <div class="mt-1 mr-2">
                <validation-provider v-slot="v" rules="required">
                  <label
                    class="block text-sm font-normal text-gray-900"
                    :class="{ 'text-red-500': v.validated && v.invalid }"
                  >
                    <span>Dos *</span>
                  </label>
                  <cm-input-number v-model="dose.dose" :classes="{ 'w-12': true }" />
                </validation-provider>
              </div>
              <div class="mt-1 mr-2">
                <label class="block text-sm font-normal text-gray-900">
                  <span>Enhet</span>
                </label>
                <cm-select v-if="unit" v-model="dose.dose_unit" :options="unit" class="w-20" />
                <cm-input-text v-else v-model="dose.dose_unit" :classes="{ 'w-16': true }" />
              </div>
              <div class="mt-1 mr-2">
                <label class="block text-sm font-normal text-gray-900">
                  <span>Frekvens</span>
                </label>
                <div class="flex">
                  <cm-input-number v-model="dose.dose_frequence" :classes="{ 'w-16': true }" class="mr-1" />
                  <cm-select v-if="schedule" v-model="dose.dose_schedule" :options="schedule" class="w-24" />
                </div>
              </div>
              <div class="mt-1 mr-2" v-if="administration">
                <label class="block text-sm font-normal text-gray-900">
                  <span>Administration</span>
                </label>
                <cm-select v-model="dose.dose_administration" :options="administration" class="w-24" />
              </div>
            </div>
            <div class="flex">
              <div class="mt-1 mr-2">
                <label class="block text-sm font-normal text-gray-900">
                  <span>Bathnr</span>
                </label>
                <cm-input-text v-model="dose.dose_batchnr" class="w-24" />
              </div>
            </div>
            <div class="flex mt-2">
              <cm-button visual="success" size="small" class="mr-2" @click.prevent="saveDose">{{
                $t(dose.id ? "button.change" : "button.save")
              }}</cm-button>
              <cm-button class="mr-2" size="small" @click.prevent="doseForm = false">{{
                $t("button.cancel")
              }}</cm-button>
            </div>
          </div>
        </div>
        <div v-if="selected" class="flex-col pb-8">
          <table class="sticky-header w-full text-xs">
            <thead ref="tableheader">
              <tr>
                <th class="py-1 px-2 cursor-pointer bg-white text-gray-800 border-b border-gray-300 truncate text-left">
                  Datum
                </th>
                <th class="py-1 px-2 cursor-pointer bg-white text-gray-800 border-b border-gray-300 truncate text-left">
                  Dos
                </th>
                <th class="py-1 px-2 cursor-pointer bg-white text-gray-800 border-b border-gray-300 truncate text-left">
                  Enhet
                </th>
                <th class="py-1 px-2 cursor-pointer bg-white text-gray-800 border-b border-gray-300 truncate text-left">
                  Frekvens
                </th>
                <th class="py-1 px-2 cursor-pointer bg-white text-gray-800 border-b border-gray-300 truncate text-left">
                  Administration
                </th>
                <th class="py-1 px-2 cursor-pointer bg-white text-gray-800 border-b border-gray-300 truncate text-left">
                  Batchnr
                </th>
              </tr>
            </thead>
            <tbody v-if="doses">
              <tr
                v-for="(dose, key) in doses"
                :key="key"
                class="group odd:bg-gray-100 first:border-t border-b border-gray-200"
                @click="openDoseForm(key)"
              >
                <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">{{ dose.dose_date }}</td>
                <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">{{ dose.dose }}</td>
                <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">{{ dose.dose_unit }}</td>
                <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                  {{ dose.dose_frequence }} {{ dose.dose_schedule }}
                </td>
                <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                  {{ dose.dose_administration }}
                </td>
                <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                  {{ dose.dose_batchnr }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div ref="menu" class="w-full flex p-2 justify-start bg-gray-200">
          <div>
            <cm-button
              class="mr-2"
              visual="danger"
              size="medium"
              :disabled="!postData.id"
              @click.prevent="removePost"
              >{{ $t("button.delete") }}</cm-button
            >
          </div>
          <div>
            <cm-button class="mr-2" size="medium" @click.prevent="cancel">{{ $t("button.cancel") }}</cm-button>
          </div>
          <div class="flex-1">
            <cm-button class="float-right" visual="success" size="medium" type="submit">{{
              $t("button.save")
            }}</cm-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  ValidationProvider,
  // ValidationObserver,
  // localize,
  extend,
} from "vee-validate";

import { required, oneOf, min_value, max_value, between } from "vee-validate/dist/rules";

extend("required", { ...required });
extend("oneOf", { ...oneOf });
extend("min_value", { ...min_value });
extend("max_value", { ...max_value });
extend("between", { ...between });

export default {
  name: "TherapyForm",
  components: {
    ValidationProvider,
  },
  props: {
    therapy: {
      type: Object,
    },
  },
  data() {
    return {
      loaded: false,
      doseForm: false,
      drugs: [],
      formStructure: [],
      variables: {},
      drug: null,
      selected: null,
      postData: {
        // id: 1,
        // drug_id: 33,
        // ordination_date: "2012-12-12",
        // start_date: "2012-12-12",
        // end_date: "2019-12-12",
        // therapy_comment: "Testdata",
        // doses: [
        //   {
        //     id: 1,
        //     dose_date: "2012-12-12",
        //     dose: 150,
        //     dose_unit: 'mg',
        //     dose_frequence: 2,
        //     dose_schedule: 'd',
        //     dose_administration: 'tablett',
        //     dose_batchnr: ''
        //   },
        //   {
        //     id: 2,
        //     dose_date: "2013-12-12",
        //     dose: 20,
        //     dose_unit: 'mg',
        //     dose_frequence: 5,
        //     dose_schedule: 'd',
        //     dose_administration: 'tablett',
        //     dose_batchnr: ''
        //   },
        //   {
        //     id: 3,
        //     dose_date: "2015-12-12",
        //     dose: 50,
        //     dose_unit: 'mg',
        //     dose_frequence: 3,
        //     dose_schedule: 'v',
        //     dose_administration: 'tablett',
        //     dose_batchnr: ''
        //   }
        // ]
      },
      doses: [],
      dose: {},
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.showForm();
    },
    async showForm() {
      await this.$store.dispatch("meta/loadFormStructure", "therapy");
      this.formStructure = this.$store.getters["meta/formstructure"];

      this.$http
        .get("meta/drugs")
        .then(response => {
          this.drugs = response.data;
          if (this.therapy) {
            this.postData = this.therapy;
            this.postData.drug_id = this.postData.drug.id;
            this.selected = true;
          }
        })
        .catch(error => {
          return [error];
        });

      this.formStructure.variables.forEach(variable => (this.variables[variable.varname] = variable));
      this.loaded = true;
    },
    setDrug(value) {
      this.drug = this.drugs.find(drug => drug.id == value);
    },
    select() {
      this.selected = true;
    },
    saveDose() {
      this.doses.push(this.dose);
      this.dose = {};
      this.doseForm = false;
    },
    mapOptions(attr) {
      if (!this.drug || !this.drug[attr]) {
        return null;
      }

      let arr = this.drug[attr].split(";");

      return arr.map(x => ({ name: x, value: x }));
    },
    openDoseForm(key) {
      this.dose = key !== undefined ? this.postData.doses[key] : {};
      this.doseForm = true;
    },
    cancel() {
      this.$modal.hide("therapy-form");
      this.$emit("cancel");
    },
  },
  computed: {
    administration() {
      return this.mapOptions("select_administration");
    },
    schedule() {
      return this.mapOptions("select_schedule");
    },
    unit() {
      return this.mapOptions("select_unit");
    },
  },
};
</script>
