<template>
  <div class="flex-col mb-1">
    <div class="w-full flex justify-start space-x-1">
      <div :class="iconColor">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
      <div>
        <h5 class="font-thin">{{ event.time }}</h5>
      </div>
    </div>
    <div class="space-x-1">
      <span class="font-semibold">{{ event.action }}</span>
      {{ entityname }}
      {{ changes }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineEvent",
  props: ["event"],
  computed: {
    iconColor() {
      return {
        "text-green-700": this.event.action === "create",
      };
    },
    entityname() {
      let parts = this.event.module.split("\\");
      return parts[parts.length - 1];
    },
    changes() {
      return "";
    },
  },
};
</script>

<style>
</style>