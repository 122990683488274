<template>
  <div class="w-full">
    <div class="flex-col">
      <div class="flex justify-between items-end mb-3 pb-2 border-b" v-for="(diagnosis, key) in diagnosis" :key="key">
        <div class="flex-col">
          <div class="font-bold">{{ diagnosis.diagnosis.code }} - {{ diagnosis.diagnosis.name }}</div>
          <div>
            {{ $t("patient.diagnosis.debute_date") }}:
            {{ formatDate(diagnosis.onset_date, diagnosis.onset_date_approx) }}
          </div>
          <div>
            {{ $t("patient.diagnosis.diagnosis_date") }}: {{ formatDate(diagnosis.date, diagnosis.date_approx) }}
          </div>
          <div>
            {{ $t("patient.diagnosis.remiss_date") }}:
            {{ formatDate(diagnosis.referral_date, diagnosis.referral_date_approx) }}
          </div>
        </div>
        <div>
          <cm-button @click="editDiagnosis('diagnosis-edit', key)">
            {{ $t("patient.diagnosis.edit") }}
          </cm-button>
        </div>
      </div>
    </div>
    <div>
      <cm-button @click="$modal.hide('diagnosis-view')">
        {{ $t("button.close") }}
      </cm-button>
    </div>
  </div>
</template>


<script>
import { dateFormat } from "@/utils/date";

export default {
  name: "DiagnosisView",
  props: ["diagnosis"],
  components: {
    //DiagnosisEdit
  },

  methods: {
    formatDate(date, dateApprox) {
      if (date) {
        switch (dateApprox) {
          case "Y":
            return dateFormat(date, "y");
          case "M":
            return dateFormat(date, "y-MM");
          default:
            return dateFormat(date);
        }
      }
      return null;
    },
    editDiagnosis(id, index) {
      this.$emit("diagnosisIndex", index);
      this.$modal.show(id);
    },
  },
};
</script>
