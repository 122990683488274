<template>
  <div v-if="info" class="text-sm">
    <div>
      <div class="w-full flex-1 rounded p-2">
        <div class="bg-white px-2 py-1 border-b border-gray-200">
          <div class="-ml-2 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-2 mt-2">
              <h3 class="text-base leading-6 font-medium text-gray-900">Senaste värden</h3>
            </div>
          </div>
          <div class="mt-2">
            <div></div>
          </div>
        </div>
      </div>

      <div class="flex flex-row">
        <div class="flex-1 max-w-md rounded m-2">
          <div class="bg-white px-2 py-1 border-b border-gray-200">
            <div class="-ml-2 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div class="ml-2 mt-2">
                <h3 class="text-base leading-6 font-medium text-gray-900">Diagnoser</h3>
              </div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <span class="inline-flex rounded-md shadow-sm">
                  <cm-button @click="$modal.show('diagnosis-add')" size="small">{{ $t("button.create") }}</cm-button>
                </span>
              </div>
            </div>
            <div class="mt-2">
              <div
                v-for="(diagnosis, key) in info.diagnoses"
                :key="key"
                class="mt-1 flex justify-between"
                @click="$modal.show('diagnosis-view')"
              >
                <div class="flex">
                  <div>{{ diagnosis.diagnosis.name }}</div>
                  <div class="mx-1 font-light">{{ diagnosis.diagnosis.code }}</div>
                </div>
                <div class="flex">
                  <div class="ml-2 text-gray-500">
                    {{ formatDate(diagnosis.onset_date, diagnosis.onset_date_approx) }}
                  </div>
                  <div class="ml-2" v-show="diagnosis.date">
                    {{ formatDate(diagnosis.date, diagnosis.date_approx) }}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <consent-overview :info="info"/>
          <therapy-overview :therapies="therapies" />
        </div>

        <div class="flex-1 rounded m-2">
          <div class="bg-white px-2 py-1 border-b border-gray-200">
            <div class="flex flex-col max-h-screen w-full">
              <div class="-ml-2 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div class="ml-2 mt-2">
                  <h3 class="text-base leading-6 font-medium text-gray-900">{{ $t("patient.overview.timeline") }}</h3>
                </div>
              </div>
              <div class="mt-2 h-full overflow-y-auto mb-36 w-full">
                <patient-timeline :events="filteredEvents" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="diagnosis-view"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded-lg h-10 p-4 shadow flex flex-col justify-between"
    >
      <diagnosis-view @diagnosisIndex="changeDiagnosis" :diagnosis="diagnoses" />
    </modal>

    <modal
      name="diagnosis-edit"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded shadow flex flex-col justify-between"
    >
      <diagnosis-edit
        :diagnosis="diagnoses[index]"
        :id="diagnoses[index] ? diagnoses[index].id : null"
        :diagnoses="diagnoses"
      />
    </modal>

    <modal
      name="diagnosis-change"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded shadow flex flex-col justify-between"
    >
      <diagnosis-change :diagnosis="diagnoses[index]" />
    </modal>

    <modal
      name="diagnosis-add"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded shadow flex flex-col justify-between"
    >
      <diagnosis-add />
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dateFormat } from "@/utils/date";
import PatientTimeline from "@/components/patient/timeline/PatientTimeline";
import TherapyOverview from "@/components/therapy/TherapyOverview";
import DiagnosisEdit from "@/components/patient/diagnosis/DiagnosisEdit";
import DiagnosisView from "@/components/patient/diagnosis/DiagnosisView";
import DiagnosisChange from "@/components/patient/diagnosis/DiagnosisChange";
import DiagnosisAdd from "@/components/patient/diagnosis/DiagnosisAdd";
import ConsentOverview from "@/components/patient/consent/ConsentOverview";

export default {
  name: "PatientBasedata",
  components: {
    PatientTimeline,
    TherapyOverview,
    DiagnosisEdit,
    DiagnosisView,
    DiagnosisChange,
    DiagnosisAdd,
    ConsentOverview,
  },
  data() {
    return {
      open: false,
      index: null,
      eventsLoaded: false,
    };
  },
  created() {
    this.load()
  },
  computed: {
    ...mapState("patient", {
      info: state => state.info,
      therapies: state => state.therapies,
      events: state => state.events,
      diagnoses: state => state.info.diagnoses,
      pmRegistrations: state => state.pmRegistrations
    }),
    filteredEvents() {
      if (!this.events) return;
      return this.events
        .filter(d => ["create", "update", "delete"].includes(d.action))
        .sort((a, b) => {
          return b.time - a.time;
        });
    },
  },
  methods: {
    async load() {
      await this.$store.dispatch("patient/pmRegistrations")
      if (this.pmRegistrations?.result) {
        this.$toasted.success(this.$tc("pm.unimported_registrations", Object.keys(this.pmRegistrations.result).length), { duration : 4000 });
      }
    },
    formatDate(date, dateApprox) {
      if (date) {
        switch (dateApprox) {
          case "Y":
            return dateFormat(date, "y");
          case "M":
            return dateFormat(date, "y-MM");
          default:
            return dateFormat(date);
        }
      }
      return null;
    },
    changeDiagnosis(event) {
      this.index = event;
    },
  },
};
</script>
