<template>
  <div>
    <module-form-header :label="label" :patient="info" :data="{}" />
    <div class="p-4">
      <diagnosis-form
        :data="diagnosis"
        :id="id"
        @diagnosis-update="diagnosisUpdate"
        @diagnosis-delete="diagnosisDelete"
        @close="$modal.hide('diagnosis-edit')"
      />
    </div>
  </div>
</template>

<script>
/**
 * onset_date must be equal or less than diagnosis date
 * remiss date must be equal or less than the greatest of onset_date and diagnosis date
 */
import { mapState } from "vuex";
import DiagnosisForm from "./DiagnosisForm";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";

export default {
  name: "DiagnosisEdit",
  props: ["id", "diagnosis"],
  components: {
    DiagnosisForm,
    ModuleFormHeader,
  },
  computed: {
    ...mapState("patient", {
      info: state => state.info,
    }),
    label() {
      return this.$t("patient.diagnosis.edit") + ` ${this.diagnosis.diagnosis.name}`;
    },
  },
  methods: {
    async diagnosisUpdate({ id, diagnosis }) {
      this.$store
        .dispatch("patient/updateDiagnosis", {
          id: id,
          data: {
            onset_date: diagnosis.onset_date,
            onset_date_approx: diagnosis.onset_date_approx,
            date: diagnosis.date,
            date_approx: diagnosis.date_approx,
            referral_date: diagnosis.referral_date,
          },
        })
        .then(() => {
          this.$modal.hide("diagnosis-edit");
        });
    },
    async diagnosisDelete(id) {
      let buttons = [
        {
          title: this.$t("button.cancel"),
          handler: () => {
            this.$modal.hide("dialog");
          },
        },
        {
          title: this.$t("button.continue"),
          class: "vue-dialog-button bg-red-500 text-white hover:bg-red-600 transition ease-in-out duration-150",
          handler: () => {
            this.$modal.hide("dialog");
            this.$store
              .dispatch("patient/deleteDiagnosis", {
                id: id,
              })
              .then(() => {
                this.$modal.hide("diagnosis-edit");
              });
          },
        },
      ];
      this.$modal.show("dialog", {
        title: this.$t("patient.diagnosis.delete.title"),
        text: this.$t("patient.diagnosis.delete.text"),
        buttons: buttons,
      });
    },
  },
};
</script>
