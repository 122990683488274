<template>
  <div class="timeline-container">
    <div class="timeline w-full">
      <div v-for="(yearly, key) in yearlyEvents" :key="key">
        <section class="year">
          <h3>{{ yearly.year }}</h3>
          <section>
            <ul class="list-none">
              <li v-for="(event, key) in yearly" :key="key">
                <div class="inline-block" v-if="event.module">
                  <div class="flex items-center">
                    <!-- <span v-if="event.action === 'created'">
                      <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        />
                      </svg>
                    </span>
                    <span v-if="event.action === 'updated'">
                      <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                        />
                      </svg>
                    </span> -->

                    <component :is="timelineComponent(event)" :event="event" />
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
//beaker - lab
//book-open - visit

import d3 from "@/components/graph/d3modules";
import { parseISO, format } from "date-fns";
import TimelineModuleEvent from "./TimelineModuleEvent";
import TimelineEvent from "./TimelineEvent";
import TimelineVisitEvent from "./TimelineVisitEvent";

export default {
  name: "PatientTimeline",
  props: {
    events: {
      type: Array,
    },
  },
  components: {
    TimelineModuleEvent,
    TimelineVisitEvent,
    TimelineEvent,
  },
  computed: {
    yearlyEvents() {
      if (!this.events) return;
      const events = this.events.map(d => {
        const time = parseISO(d.time);
        return {
          ...d,
          year: time.getFullYear(),
          time: format(time, "yyyy-MM-dd hh:ii"),
        };
      });

      const grouped = Array.from(d3.group(events, d => d.year)).reduce((acc, cur) => {
        acc[cur[0]] = cur[1];
        return acc;
      }, {});

      const sorted = Object.keys(grouped)
        .sort((a, b) => {
          return b - a;
        })
        .reduce((acc, cur, idx) => {
          grouped[cur].year = cur;
          acc[idx] = grouped[cur];
          return acc;
        }, {});

      return sorted;
    },
  },
  methods: {
    timelineComponent(event) {
      switch (event.module) {
        case "App\\Entity\\ModuleRegistration":
          return "timeline-module-event";
        case "App\\Entity\\Visit":
          return "timeline-visit-event";
        default:
          return "timeline-event";
      }
    },
  },
};
</script>

<style scoped>
.timeline {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
.timeline:after {
  content: "";
  width: 3px;
  position: absolute;
  top: 0.5rem;
  bottom: 0rem;
  left: 58px;
  z-index: 1;
  background: steelblue;
}
.timeline h3 {
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
  color: rgb(14, 14, 14);
  margin: 0;
  @apply text-sm;
  font-weight: 400;
}
@media (min-width: 62em) {
  . #timeline h3 {
    @apply text-sm;
  }
}
.timeline section.year {
  position: relative;
}
.timeline section.year:first-child section {
  margin-top: -1.3em;
  padding-bottom: 0px;
}
.timeline section.year section {
  position: relative;
  padding-bottom: 1.25em;
  margin-bottom: 2.2em;
}
.timeline section.year section h4 {
  position: absolute;
  bottom: 0;
  @apply text-sm;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  padding: 0 0 0 89px;
  color: #c5c5c5;
}
@media (min-width: 62em) {
  . .timeline section.year section h4 {
    @apply text-sm;
  }
}
.timeline section.year section ul {
  list-style-type: none;
  padding: 0 0 0 75px;
  margin: -1.35rem 0 1em;
  max-width: 32rem;
  @apply text-sm;
}
@media (min-width: 62em) {
  . #timeline section.year section ul {
    @apply text-sm;
    padding: 0 0 0 81px;
  }
}
.timeline section.year section ul:last-child {
  margin-bottom: 0;
}
.timeline section.year section ul:first-of-type:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #c5c5c5;
  border: 2px solid #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 52px;
  top: 3px;
  z-index: 2;
}
.timeline section.year section ul li {
  margin-left: 0.5rem;
}
.timeline section.year section ul li:before {
  content: "";
  margin-left: -0.5rem;
  padding-right: 0.3rem;
}
.timeline section.year section ul li:not(:first-child) {
  margin-top: 0.5rem;
}
.timeline section.year section ul li span.price {
  color: mediumturquoise;
  font-weight: 500;
}
</style>
