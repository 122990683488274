<template>
  <div class="flex-col mb-1" v-if="!event.data.registered_with_visit">
    <div class="w-full flex justify-start space-x-1">
      <div :class="iconColor">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
          ></path>
        </svg>
      </div>
      <div>
        <h5 class="font-thin">{{ event.time }}</h5>
      </div>
    </div>
    <div class="space-x-1">
      <span class="font-semibold">{{ event.action }}</span>
      <span>{{ event.entity_name }}</span>
    </div>
  </div>
</template>


<script>
export default {
  name: "TimelineModuleEvent",
  props: ["event"],
  computed: {
    iconColor() {
      return {
        "text-green-700": this.event.action === "create",
      };
    },
  },
};
</script>

<style>
</style>