<template>
    <div class="flex flex-row">
        <div class="flex-1 max-w-md rounded mt-2">
            <div class="bg-white px-2 py-1 border-b border-gray-200">
                <div class="-ml-2 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div class="ml-2 mt-2">
                        <h3 class="text-base leading-6 font-medium text-gray-900">{{ $t("patient.consent.consents") }}</h3>
                    </div>
                    <div class="ml-4 mt-2 flex-shrink-0">
                        <span class="inline-flex rounded-md shadow-sm">
                            <cm-button @click="$modal.show('patient-consent')" size="small">{{ $t("patient.consent.handle") }}</cm-button>
                        </span>
                    </div>
                </div>
                <div class="mt-2">
                    <div v-if="registryConsents.length > 0">
                        <table>
                            <tr>
                                <td class="font-semibold pr-2">{{ $t("patient.consent.registry") }}</td>
                                <td class="font-semibold">{{ $t("patient.consent.consent") }}</td>
                            </tr>
                            <tr v-for="(consent, key) in registryConsents" :key="key">
                                <td class="pr-2">{{ consent.registry.name }}</td>
                                <td>{{ status(consent.status) }}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-if="studyConsents.length > 0">
                        <table>
                            <tr>
                                <td class="font-semibold pr-2">{{ $t("patient.consent.study") }}</td>
                                <td class="font-semibold">{{ $t("patient.consent.consent") }}</td>
                            </tr>
                            <tr v-for="(consent, key) in studyConsents" :key="key">
                                <td class="pr-2">{{ consent.study.name }}</td>
                                <td>{{ status(consent.status) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ConsentOverview",
    props: ["info"],
    computed: {
        registryConsents() {
            if (!this.info.consents) return []

            return this.info.consents.filter(d => d.registry);
        },
        studyConsents() {
            if (!this.info.consents) return []

            return this.info.consents.filter(d => d.study);
        },
    },
    methods: {
        status(status) {
            switch(status) {
                case 0:
                return this.$t("patient.consent.options.status.no")
                case 1:
                return this.$t("patient.consent.options.status.yes")
                case 99:
                return this.$t("patient.consent.options.status.not_obtained");
            }
        }
    }
}
</script>