<template>
  <div class="bg-white px-2 py-1 border-b border-gray-200 mt-2">
    <div class="-ml-2 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
      <div class="ml-2 mt-2">
        <h3 class="text-base leading-6 font-medium text-gray-900">{{ $t("header.therapies") }}</h3>
      </div>
      <div class="ml-4 mt-2 flex-shrink-0">
        <span class="inline-flex rounded-md shadow-sm">
          <cm-button size="small" @click="openForm({})">{{ $t("therapy.new") }}</cm-button>
        </span>
      </div>
    </div>
    <div class="mt-2" v-if="currentTherapies">
      <div class="text-gray-900">
        <h3 class="font-bold text-gray-600">{{ $t("therapy.ongoing") }}</h3>
        <div
          v-for="(t, key) in currentTherapies"
          :key="key"
          class="mt-1 pl-2 cursor-pointer flex justify-between hover:bg-gray-100"
          @click="openForm(t)"
        >
          <div class="flex">
            <div>{{ t.drug.name }}</div>
            <div class="mx-1 font-light">{{ lastDose(t) }}</div>
          </div>
          <div>
            <div>{{ niceDate(t.start_date) }}</div>
          </div>
        </div>
      </div>
      <div v-if="previousTherapies" class="text-gray-700">
        <h3 class="font-bold text-gray-600">{{ $t("therapy.ended") }}</h3>
        <div
          v-for="(t, key) in previousTherapies"
          :key="key"
          class="mt-1 pl-2 cursor-pointer flex justify-between"
          @click="openForm(t)"
        >
          <div class="flex">
            <div>{{ t.drug.name }}</div>
          </div>
          <div class="flex">
            <div>{{ niceDate(t.start_date) }}</div>
            <div class="ml-1">- {{ niceDate(t.end_date) }}</div>
          </div>
        </div>
      </div>
    </div>

    <modal
      name="therapy-form"
      :click-to-close="false"
      :scrollable="false"
      :resizable="false"
      :draggable="true"
      :adaptive="true"
      :reset="true"
      :pivotY="0.1"
      :height="'auto'"
      classes="modal-container bg-white rounded-md shadow-md transform transition-all object-contain"
    >
      <therapy-form :therapy="therapy" />
    </modal>
  </div>
</template>

<script>
import TherapyForm from "@/components/therapy/TherapyForm";
import { dateFormat } from "@/utils/date";

export default {
  name: "TherapyOverview",
  components: {
    TherapyForm,
  },
  props: {
    therapies: {
      type: Array,
    },
  },
  data() {
    return {
      therapy: null,
      modalHeight: 0,
    };
  },
  created() {
    this.modalHeight = window.innerHeight - 20;
  },
  methods: {
    niceDate(date) {
      return date ? dateFormat(date) : null;
    },
    lastDose(therapy) {
      let d = therapy.doses;

      if (!d.length) {
        return null;
      }

      return d[0].dose + (d[0].dose_unit ?? "") + " " + (d[0].dose_administration ?? "");
    },
    openForm(t) {
      this.therapy = t;
      this.$modal.show("therapy-form");
    },
  },
  computed: {
    currentTherapies() {
      return this.therapies ? this.therapies.filter(d => !d.end_date) : [];
    },
    previousTherapies() {
      return this.therapies ? this.therapies.filter(d => d.end_date) : [];
    },
  },
};
</script>
