<template>
  <form @submit="diagnosisChange" class="bg-white mx-4 mt-4 mb-4 cm-form">
    <div>
      <span class="font-bold"
        >{{ $t("patient.diagnosis.change_from") }} {{ diagnosis.diagnosis.code }} - {{ diagnosis.diagnosis.name }}
      </span>
      <br />
      <span class="font-italic"> {{ $t("patient.diagnosis.change_info") }} </span>
      <div class="relative py-2">
        <label>{{ $t("patient.diagnosis.selectable") }}</label>
        <div class="modal">
          <cm-search-select
            id="diagnosis_id"
            name="name"
            valueProp="id"
            placeholder="Välj diagnos"
            :options="categoryDiagnoses()"
            @select="diagnosis_id = $event"
            :style="`width: ${searchSelectWidth}px;`"
          />
        </div>
      </div>
    </div>
    <cm-button type="submit" class="btn-green mr-2 p-2 rounded">{{ $t("button.save") }}</cm-button>
    <cm-button type="cm-button" class="btn-red mr-2 p-2 rounded" @click="$modal.hide('diagnosis-change')">{{
      $t("button.cancel")
    }}</cm-button>
  </form>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DiagnosisChange",
  props: ["diagnosis"],
  methods: {
    diagnosisChange(e) {
      e.preventDefault();
      this.pendingSave = true;
      this.$store
        .dispatch("patient/updateDiagnosis", {
          id: this.diagnosis.id,
          data: { diagnosis_id: parseInt(this.diagnosis_id) },
        })
        .then(() => {
          this.pendingSave = false;
          this.$modal.hide("diagnosis-change");
        });
    },
    categoryDiagnoses: function () {
      let category = this.categories
        .filter(c => this.diagnosis.diagnosis.id == c.diagnosis.id)
        .map(function (c) {
          return c.category.id;
        });

      var categoryDiagnosesIds = this.categories
        .filter(c => category[0] == c.category.id && category[0] != c.diagnosis.id)
        .map(function (c) {
          return c.diagnosis.id;
        });

      if (this.diagnoses) {
        return this.diagnoses.filter(d => categoryDiagnosesIds.indexOf(d.id));
      }

      return [];
    },
  },
  data() {
    return {
      diagnosis_id: null,
    };
  },
  created() {
    this.$store.dispatch("meta/diagnoses");
    this.$store.dispatch("meta/registryDiagnoses");
  },
  computed: {
    ...mapState("meta", {
      diagnoses: state => state.diagnoses,
      categories: state => state.registryDiagnoses,
    }),
    searchSelectWidth() {
      //Update to something smarter eg parent div width but necessary set height in modal when position is absolute
      return 534;
    },
  },
};
</script>