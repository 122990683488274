<template>
  <validation-observer :ref="'formobserver'" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(save)" v-if="loaded">
      <div class="mr-4 flex flex-col md:flex-row" v-if="!data.id">
        <div class="lg:w-1/3">
          <label class="block text-base leading-10">{{ $t("patient.diagnosis.diagnosis") }}</label>
        </div>
        <div class="lg:w-2/3 modal">
          <validation-provider v-slot="validation" rules="required" :name="$t('patient.diagnosis.diagnosis')">
            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
              {{ validation.errors[0] }}
            </p>
            <cm-search-select
              :options="selectableDiagnoses"
              ref="diagnosis_id"
              name="name"
              valueProp="id"
              v-model="diagnosis.diagnosis_id"
              :selected="diagnosis.diagnosis_id"
              :placeholder="$t('patient.diagnosis.select')"
              @select="diagnosis.diagnosis_id = $event"
              :style="`width: ${searchSelectWidth}px;`"
            />
          </validation-provider>
         </div>
      </div>

      <div class="mr-4 flex flex-col md:flex-row">
        <div class="lg:w-1/3">
          <label class="block text-base">{{ $t("patient.diagnosis.debute_date") }}</label>
        </div>
        <validation-provider v-slot="validation" rules="required" :name="$t('patient.diagnosis.debute_date')">
          <div>
            <cm-approx-datepicker
              name="onset_date"
              :max-date="maxDateDebute"
              :min-date="minDateDebute"
              @approx="diagnosis.onset_date_approx = $event"
              :approx="diagnosis.onset_date_approx"
              v-model="diagnosis.onset_date"
              :title="$t('patient.diagnosis.debute_date')"
            />
            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
              {{ validation.errors[0] }}
            </p>
          </div>
        </validation-provider>
      </div>

      <div class="mr-4 flex flex-col md:flex-row">
        <div class="lg:w-1/3">
          <label class="block text-base">{{ $t("patient.diagnosis.diagnosis_date") }}</label>
        </div>
        <validation-provider v-slot="validation" rules="required" :name="$t('patient.diagnosis.diagnosis_date')">
          <div>
            <cm-approx-datepicker
              name="date"
              :max-date="maxDateDiagnosis"
              :min-date="minDateDiagnosis"
              :ceil="true"
              @approx="diagnosis.date_approx = $event"
              :approx="diagnosis.date_approx"
              v-model="diagnosis.date"
              :title="$t('patient.diagnosis.debute_date')"
            />
            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
              {{ validation.errors[0] }}
            </p>
          </div>
        </validation-provider>
      </div>

      <div class="mr-4 flex flex-col md:flex-row">
        <div class="lg:w-1/3">
          <label class="block text-base">{{ $t("patient.diagnosis.remiss_date") }}</label>
        </div>
        <validation-provider v-slot="validation" :name="$t('patient.diagnosis.remiss_date')">
          <div>
            <cm-datepicker2
              name="referral_date"
              :max-date="maxDateRemiss"
              :min-date="minDateRemiss"
              v-model="diagnosis.referral_date"
              :title="$t('patient.diagnosis.remiss_date')"
            />
            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
              {{ validation.errors[0] }}
            </p>
          </div>
        </validation-provider>
      </div>
      <div class="mt-4 space-x-1">
        <cm-button type="submit" :disabled="invalid" visual="default" size="medium">{{ $t("button.save") }}</cm-button>
        <cm-button type="button" @click="close">
          {{ $t("button.cancel") }}
        </cm-button>
        <cm-button v-if="data.id" visual="danger" @click="diagnosisDelete()">{{ $t("button.delete") }}</cm-button>
        <cm-button v-if="data.id" visual="danger" @click="$modal.show('diagnosis-change')">{{
          $t("button.change")
        }}</cm-button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
/**
 * onset date must be less or equal then diagnosis date and less or equal than remiss date if set
 * remiss date must be null, between or the same as onset and/or diagnosis date
 * diagnosis date must be less or equal today, later or equal than onset date and later or equal than remiss date if set
 */
import { mapState } from "vuex";
import { ValidationObserver, ValidationProvider, localize, extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";

extend("required", required);
extend("regex", regex);

function loadVeeValidateLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

export default {
  name: "DiagnosisForm",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: ["data"],
  watch: {
    loaded: "setSearchSelectWidth",
  },
  data() {
    return {
      loaded: false,
      category: null,
      id: null,
      searchSelectWidth: null,
      diagnosis: {
        onset_date: null,
        onset_date_approx: null,
        date: null,
        date_approx: null,
        referral_date: null,
        diagnosis_id: null,
      },
    };
  },
  created() {
    this.load();
  },
  beforeMount() {
    loadVeeValidateLocale(this.userLocale ?? "sv");
  },
  computed: {
    ...mapState("meta", {
      diagnoses: state => state.diagnoses,
      registryDiagnoses: state => state.registryDiagnoses,
    }),
    ...mapState("user", {
      registries: state => state.registries,
    }),
    ...mapState("patient", {
      restrictions: state => state.restrictions,
      info: state => state.info,
    }),
    maxDateDebute() {
      return this.diagnosis.referral_date ? new Date(this.diagnosis.referral_date) : this.diagnosis.date ? new Date(this.diagnosis.date) : new Date();
    },
    minDateDebute() {
      return this.restrictions.dateMin;
    },
    maxDateDiagnosis() {
      return new Date();
    },
    minDateDiagnosis() {
      return this.diagnosis.referral_date ? new Date(this.diagnosis.referral_date) : this.diagnosis.onset_date ? new Date(this.diagnosis.onset_date) : this.restrictions.dateMin;
    },
    maxDateRemiss() {
      return this.diagnosis.date ? new Date(this.diagnosis.date) : new Date();
    },
    minDateRemiss() {
      return this.diagnosis.onset_date ? new Date(this.diagnosis.onset_date) : this.restrictions.dateMin;
    },
    selectableDiagnoses() {
      let categories = [];
      this.registries.map(function (r) { //Get diagnosis categories for user registries
        r.diagnosis_categories.forEach(c => {
          categories.push(c.id);
        });
      });

      let patientDiagnoses = [];
      this.info.diagnoses.map(function (d) { //Get patients old diagnoses
        patientDiagnoses.push(d.diagnosis.id)
      });

      let categoryDiagnoses = [];
      this.registryDiagnoses.map(function (r) {
        //Check if user has access to diagnosis diagnosis category, the diagnosis is not pushed before and the patient does not already have diagnosis
        if (categories.indexOf(r.category.id) != -1 && categoryDiagnoses.map(d => d.id).indexOf(r.diagnosis.id) == -1 && patientDiagnoses.indexOf(r.diagnosis.id) == -1) {
          categoryDiagnoses.push(r.diagnosis);
        }
      });

      return categoryDiagnoses;
    },
  },
  methods: {
    setSearchSelectWidth() {
      this.$nextTick(() => {
        this.searchSelectWidth = this?.$refs?.diagnosis_id?.$el?.clientWidth || null;
      });
    },
    save() {
      if (this.id) {
        this.$emit("diagnosis-update", { id: this.id, diagnosis: this.diagnosis });
      } else {
        this.$emit("diagnosis-add", this.diagnosis);
      }
    },
    async load() {
      await this.$store.dispatch("user/getRegistries");
      await this.$store.dispatch("meta/diagnoses");
      await this.$store.dispatch("meta/registryDiagnoses");

      this.id = this?.data?.id;
      this.diagnosis.onset_date = this?.data?.onset_date || null;
      this.diagnosis.onset_date_approx = this?.data?.onset_date_approx || null;
      this.diagnosis.date = this?.data?.date || null;
      this.diagnosis.date_approx = this?.data?.date_approx || null;
      this.diagnosis.referral_date = this?.data?.referral_date || null;
      this.diagnosis.diagnosis_id = this?.data?.diagnosis_id || null;

      this.loaded = true;
    },
    close() {
      this.$emit("close");
    },
    diagnosisDelete() {
      this.$emit("diagnosis-delete", this.id);
    },
  },
};
</script>
