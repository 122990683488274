<template>
  <div class="flex-col mb-1">
    <div class="w-full flex justify-start space-x-1">
      <div :class="iconColor">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
          ></path>
        </svg>
      </div>
      <div>
        <h5 class="font-thin">{{ event.time }}</h5>
      </div>
    </div>
    <div class="space-x-1">
      <span class="font-semibold">{{ event.action }}</span>
      <span>visit</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineVisitEvent",
  props: ["event"],
  computed: {
    iconColor() {
      return {
        "text-green-700": this.event.action === "create",
      };
    },
  },
};
</script>

<style>
</style>