<template>
  <div>
    <module-form-header :label="$t('patient.diagnosis.add')" :patient="info" :data="{}" />
    <div class="p-4">
      <diagnosis-form :data="{}" @diagnosis-add="diagnosisAdd" @close="$modal.hide('diagnosis-add')" />
    </div>
  </div>
</template>

<script>
/**
 * onset_date must be equal or less than diagnosis date
 * remiss date must be equal or less than the greatest of onset_date and diagnosis date
 */
import { mapState } from "vuex";
import DiagnosisForm from "./DiagnosisForm";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";

export default {
  name: "DiagnosisAdd",
  components: {
    DiagnosisForm,
    ModuleFormHeader,
  },
  computed: {
    ...mapState("patient", {
      info: state => state.info,
    }),
  },
  methods: {
    async diagnosisAdd(diagnosis) {

      this.$store.dispatch("patient/addDiagnosis", diagnosis).then(() => {
        this.$modal.hide("diagnosis-add");
        this.$store.dispatch("patient/getConsents").then((data) => {
          if (data.data.length != this.info.consents.length) {
            let buttons = [
              {
                title: this.$t("patient.dialog.no"),
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
              {
                title: this.$t("patient.dialog.yes"),
                handler: () => {
                  this.$modal.hide("dialog");
                  this.$modal.show("patient-consent");
                },
              },
            ];

            this.$modal.show("dialog", {
              title: this.$t("patient.consent.dialog.edit.title"),
              text: this.$t("patient.consent.dialog.edit.text", {
                'registry': data.data[data.data.length - 1].registry.name
              }),
              buttons: buttons,
            });
          }
        });
      });
    },
  },
};
</script>
